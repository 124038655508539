module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"iman","preview":false,"prismicToolbar":false,"pages":[{"type":"Home","match":"/","path":"/","component":"/opt/build/repo/src/templates/home.js"},{"type":"Portfolio","match":"/casos-de-exito","path":"/casos-de-exito","component":"/opt/build/repo/src/templates/portfolio.js"},{"type":"Blogs","match":"/descubrimientos","path":"/descubrimientos","component":"/opt/build/repo/src/templates/blogs.js"},{"type":"What_we_do","match":"/que-hacemos","path":"/que-hacemos","component":"/opt/build/repo/src/templates/what-we-do.js"},{"type":"About_us","match":"/conocenos","path":"/conocenos","component":"/opt/build/repo/src/templates/about-us.js","langs":["es-es"]},{"type":"Blog","match":"/descubrimientos/:uid","path":"/descubrimientos","component":"/opt/build/repo/src/templates/blog.js"},{"type":"Study_case","match":"/casos-de-exito/:uid","path":"/casos-de-exito","component":"/opt/build/repo/src/templates/case-study.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
