/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = () => {


      /* INIT FUNCTIONS */


      setTimeout(() => {
            limitsIds();
            waypointsInit();
            headerInit();
      }, 100)
        
      
      // WAYPOINTS
   
      function waypointsInit() {
      
      
            // VARS
      
            var $logo = document.getElementsByClassName('js-logo__svg')[0];
            var $nav = document.getElementsByClassName('js-nav-btn')[0];
      
      
            // SECTION
            var $headerWaypointsArray = document.querySelectorAll('.js-header-waypoint');
            Array.prototype.forEach.call($headerWaypointsArray, function (el, i) {
      
      
                  // Vars
                  var $section = el;
                  var sectionId = $section.getAttribute('id');
      


                  // Down
      
                  new window.Waypoint({
                        element: document.getElementById(sectionId),
                        handler: function (direction) {
      
                              if (direction == 'down') {
      
                                    // console.log( 'Down', sectionId );
      
                                    $section.getAttribute('data-logo') === 'dark' ? $logo.classList.add('is-dark') : $logo.classList.remove('is-dark');
                                    $section.getAttribute('data-nav') === 'dark' ? $nav.classList.add('is-dark') : $nav.classList.remove('is-dark');
      
                              }
                        },
                        offset: 20
                  });
      
                  // Up
      
                  new window.Waypoint({
                        element: document.getElementById(sectionId),
                        handler: function (direction) {
      
                              if (direction == 'up') {
      
                                    // console.log( 'Up', sectionId );
      
                                    $section.getAttribute('data-logo') === 'dark' ? $logo.classList.add('is-dark') : $logo.classList.remove('is-dark');
                                    $section.getAttribute('data-nav') === 'dark' ? $nav.classList.add('is-dark') : $nav.classList.remove('is-dark');
      
      
                              }
                        },
                        offset: function () {
                              return -this.element.clientHeight
                        }
                  });
      
            });
      
      } // waypointsInit

      
      // LIMITS IDS

      function limitsIds() {
            var count = 1;

            // .each() para cada boton
            var $headerWaypointsArray = document.querySelectorAll('.js-header-waypoint');
            Array.prototype.forEach.call($headerWaypointsArray, function (el, i) {

                  if (!el.getAttribute('id')) {
                        el.setAttribute('id', 'section-' + count);
                        count++;
                  }

            });

      } // limitsIds

            
    
      

      
      /* ***** FUNCIONES ***** */

      // Returns a function, that, as long as it continues to be invoked, will not
      // be triggered. The function will be called after it stops being called for
      // N milliseconds. If `immediate` is passed, trigger the function on the
      // leading edge, instead of the trailing.
      function debounce(func, wait, immediate) {
            var timeout;
            return function () {
                  var context = this, args = arguments;
                  var later = function () {
                        timeout = null;
                        if (!immediate)
                              func.apply(context, args);
                  };
                  var callNow = immediate && !timeout;
                  clearTimeout(timeout);
                  timeout = setTimeout(later, wait);
                  if (callNow)
                        func.apply(context, args);
            };
      } // debounce



      // SCROLL POSITION

      function saveScrollPosition() {
            if (!localStorage.getItem('scrollPosition')) {
                  localStorage.setItem('scrollPosition', window.pageYOffset);
            }
      } // saveScrollPosition

      function resetScrollPosition() {
            if (localStorage.getItem('scrollPosition')) {
                  document.documentElement.scrollTop = localStorage.getItem('scrollPosition');
                  localStorage.removeItem('scrollPosition');
            }
      } // resetScrollPosition




      // HEADER

      function headerInit() {

            var $viewport = document.getElementsByClassName('js-viewport')[0];
            var $header = document.getElementsByClassName('js-header')[0];
            var $btn = document.getElementsByClassName('js-nav-btn')[0];
            var $popup = document.getElementsByClassName('js-header-popup')[0];
            var $nav = document.getElementsByClassName('js-nav')[0];
            var $contact = document.getElementsByClassName('js-contact')[0];
            var popupInTimer = null;
            var popupOutTimer = null;
            var popupToggleTimer = null;
            var $goToIntroBtn = document.getElementsByClassName('js-goTo-intro')[0];
            var $goToIntroBtn2 = document.getElementsByClassName('js-goTo-intro')[1];
            var $goToIntroBtn3 = document.getElementsByClassName('js-goTo-intro')[2];



            // Show Popup

            function showPopup($el, activeBtn) {

                  // Clear timers
                  clearTimeout(popupOutTimer);
                  popupOutTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Save body's scroll position
                  saveScrollPosition();

                  // Display block to popup
                  $popup.style.display = 'block';
                  $el.style.display = 'block';

                  $el.focus();

                  // Show popup
                  $header.classList.add('show-popup');
                  $header.classList.add('show-content');
                  // Set active btn
                  if (activeBtn) {
                        $btn.classList.add('is-active');
                  }

                  // Hide webpage after popup's IN animation
                  popupInTimer = setTimeout(function () {
                        // Hide webpage
                        $viewport.classList.add('u-mask');
                        // Remove popop's Fixed style
                        $popup.classList.add('not-fixed');
                        // Scroll Top Popup
                        document.documentElement.scrollTop = 0;
                  }, 500);

            } // showPopup

            // Hide Popup

            function hidePopup($el, activeBtn) {

                  // Reset Timers
                  clearTimeout(popupInTimer);
                  popupInTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Set popup's Fixed style
                  $popup.classList.remove('not-fixed');
                  // Show webpage
                  $viewport.classList.remove('u-mask');
                  // Reset body's scroll position
                  resetScrollPosition();
                  // Trigger out animation
                  $header.classList.add('is-closing');
                  // Set active btn
                  if (activeBtn) {
                        $btn.classList.remove('is-active');
                  }
                  // Hide popup after OUT animation
                  popupOutTimer = setTimeout(function () {
                        // Hide popup
                        $popup.style.display = 'none';

                        // .each(function(){ $(this) })
                        var $sections = $popup.querySelectorAll('.c-header-popup__section');
                        Array.prototype.forEach.call($sections, function (el, i) {
                              el.style.display = 'none';
                        });

                        // Reset popup's animation classes
                        $header.classList.remove('show-popup', 'show-content', 'is-closing');
                        // Set Nav state
                        $header.setAttribute('data-visible-nav', false);

                  }, 550);

            } // hidePopup




            // Toggle Popup

            function togglePopup($out, $in) {

                  // Clear timer
                  clearTimeout(popupInTimer);
                  popupInTimer = null;
                  clearTimeout(popupOutTimer);
                  popupOutTimer = null;

                  // Trigger Out animation
                  $header.classList.remove('show-content');

                  // Toggle popups after OUT animation
                  popupToggleTimer = setTimeout(function () {
                        // Hide Out section
                        $out.style.display = 'none';
                        // Show In section
                        $in.style.display = 'block';

                        // jQuery hack
                        // $in.height();

                        // Reset scroll
                        document.documentElement.scrollTop = 0;
                        // Trigger In animation
                        $header.classList.add('show-content');
                  }, 450);

            } // togglePopup


            // NAV BTN CLICK
            if ($goToIntroBtn) {
                  $goToIntroBtn.onclick = function () {

                        document.documentElement.scrollTop = 0;
                        return false;
                  }
            }

            if ($goToIntroBtn2) {
                  $goToIntroBtn2.onclick = function () {

                        document.documentElement.scrollTop = 0;
                        return false;
                  }
            }

            if ($goToIntroBtn3) {
                  $goToIntroBtn3.onclick = function () {

                        document.documentElement.scrollTop = 0;
                        return false;
                  }
            }

            if ($btn) {
                  $btn.onclick = function () {


                        if ($header.getAttribute('data-visible-nav') == 'false' && $header.getAttribute('data-visible-contact') == 'false') {
                              // Si el Form y Nav estÃ¡n cerrados
                              showPopup($nav, true);
                              // Set Nav state
                              $header.setAttribute('data-visible-nav', true);

                        } else if ($header.getAttribute('data-visible-contact') == 'true') {
                              // Si estÃ¡ abierto el Form
                              hidePopup($contact, true);
                              // Set Nav state
                              $header.setAttribute('data-visible-contact', false);

                        } else if ($header.getAttribute('data-visible-nav') == 'true') {
                              // Si estÃ¡ abierto el Form
                              hidePopup($contact, true);
                              // Set Nav state
                              $header.setAttribute('data-visible-contact', false);

                        }
                        this.blur();

                        return false;
                  };
            }


            // CONACT BTN

            // .each() para cada boton
            var $contactBtnArray = document.querySelectorAll('.js-show-contact');
            Array.prototype.forEach.call($contactBtnArray, function (el, i) {
                  el.onclick = function () {

                        if ($header.getAttribute('data-visible-nav') == 'false') {
                              // Si el Form y Nav estÃ¡n cerrados
                              showPopup($contact, true);
                              // Set Nav state
                              $header.setAttribute('data-visible-contact', true);

                        } else {
                              togglePopup($nav, $contact);
                              // Set Nav state
                              $header.setAttribute('data-visible-nav', false);
                              $header.setAttribute('data-visible-contact', true);

                        }
                        this.blur();

                        return false;
                  };
            });



      } // headerInit



}