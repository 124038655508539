// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/what-we-do.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

